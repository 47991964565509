import { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ExaptiveClient from '@exaptive/client';
import LoadingContainer from '~/components/LoadingContainer';
import { CityContext } from '~/services/Contexts';
import CityConfigClient from '~/services/CityConfig';
import UserProfile from '~/services/UserProfile';
import { getParams } from '~/components/paramUtils';

const DEFAULT_SUPPORT_EMAIL = 'support@exaptive.com';

export default class CityContextProvider extends Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    unauthorizedHandler: PropTypes.func,
    token: PropTypes.string,
  };

  static defaultProps = {
    unauthorizedHandler: () => {},
  };

  state = {
    cityInfo: null,

    cityConfig: null,
    cognitiveCityConfig: null,

    // Deprecated ambiguous config accessors
    city: null,
    cognitiveCity: null,

    // Clients for making external requests
    exaptiveClient: null,
    gaClient: null,
    userProfile: null,
    cityConfigClient: null,

    // Store handlers on the state to prevent unnecessary rerenders
    refreshCityContext: null,
    getCityUuid: null,
    getUserElementTypeUuid: null,
    isDevMode: null,
    isCommunityPageEnabled: null,
    getSupportEmail: null,
  };

  constructor(props) {
    super(props);
    this.state.refreshCityContext = this.refreshCityContext;
    this.state.getCityUuid = this.getCityUuid;
    this.state.getUserElementTypeUuid = this.getUserElementTypeUuid;
    this.state.isDevMode = this.isDevMode;
    this.state.isCommunityPageEnabled = this.isCommunityPageEnabled;
    this.state.getSupportEmail = this.getSupportEmail;
  }

  _initialize = async () => {
    const exaptiveClient = ExaptiveClient.create({
      url: '/',
      token: this.props.token,
      debug: false,
      unauthorizedHandler: this.props.unauthorizedHandler,
    });

    const cityConfigClient = CityConfigClient.create({
      exaptiveClient,
    });
    const userProfile = UserProfile.create({
      exaptiveClient,
    });

    await new Promise(resolve => {
      this.setState(
        {
          exaptiveClient,
          userProfile,
          cityConfigClient,
        },
        resolve
      );
    });

    return this.state.refreshCityContext();
  };

  refreshCityContext = async () => {
    const cityConfig = await this.state.cityConfigClient.getCity();

    return new Promise(resolve => {
      this.setState(cityConfig, () => {
        resolve();
      });
    });
  };

  getCityUuid = () => this.state.cityInfo.uuid;

  getUserElementTypeUuid = () =>
    get(this.state, 'cityInfo.userType') ||
    get(this.state, 'cognitiveCity.cognet.userConcept', '');

  isDevMode = () => {
    const params = getParams(this.props.location?.search);
    return (
      !!get(this.state, 'cityConfig.cityFeatures.devOnlyFeatures') ||
      params.devOnlyFeatures === 'true'
    );
  };

  isCommunityPageEnabled = () => {
    const { cognitiveCity } = this.state;
    const { viewCollection, showExploratoryViewerLink } =
      cognitiveCity.pages.community;

    const hasViews = !!(viewCollection || []).length;
    return hasViews || showExploratoryViewerLink;
  };

  getSupportEmail = () =>
    get(
      this.state,
      'cityConfig.notifications.supportEmail',
      DEFAULT_SUPPORT_EMAIL
    );

  _checkForRequiredCognetConfigOptions(options) {
    const requiredOptions = ['cityOrigin', 'city', 'userConcept'];
    const missingOptions = requiredOptions.filter(key => !options[key]);

    if (missingOptions.length) {
      throw new Error(
        `Missing cognet configuration values: ${missingOptions.join(', ')}`
      );
    }
  }

  render() {
    return (
      <LoadingContainer load={this._initialize}>
        {() => (
          <CityContext.Provider value={this.state}>
            {this.props.children}
          </CityContext.Provider>
        )}
      </LoadingContainer>
    );
  }
}
