import { isObject } from 'lodash';

const UserProfile = {
  create,
};

function create({ exaptiveClient }) {
  const that = {
    getUserElementFromId,
    getRelatedElementTypes,
    checkConnection,
    addConnection,
    removeConnection,
  };

  return that;

  async function _getConnections({ src, dst = null }) {
    const where = dst ? { src, dst } : { src };

    const res = await exaptiveClient.connection.list({
      data: { where },
      params: { cityNamespace: 'main' },
    });

    if (!res.data) {
      throw new Error(`No connection found with uuid ${src}`);
    }

    return res.data;
  }

  async function _listConnectionTypes() {
    const res = await exaptiveClient.connectionType.list({
      params: { cityNamespace: 'main' },
    });

    if (!res.data || res.data.length === 0) {
      throw new Error(`No connection types found`);
    }

    return res.data;
  }

  async function _getAllowedConnectionTypesFromSrcDst({ src, dst }) {
    // connectionTypes.list doesn't support src & dst in where clause.
    // Pulling all and filtering here.
    const connectionTypes = await _listConnectionTypes();

    const allowedConnectionTypes = connectionTypes.filter(connType => {
      if (connType.srcDstTypes === null) return true;

      if (Array.isArray(connType.srcDstTypes)) {
        return (
          connType.srcDstTypes.filter(
            srcDstType => srcDstType.includes(src) && srcDstType.includes(dst)
          ).length > 0
        );
      }

      return false;
    });

    return allowedConnectionTypes;
  }

  async function _getProfileConnectionTypeFromSrcDst({
    srcElement,
    dstElement,
  }) {
    const allowedConnectionTypes = await _getAllowedConnectionTypesFromSrcDst({
      src: srcElement.uuid,
      dst: dstElement.uuid,
    });

    const connectionType = allowedConnectionTypes.filter(
      connType => connType.name === 'is'
    )[0];

    if (!connectionType) {
      throw new Error(
        `Could not determine connection type for ${srcElement.typeName} to ${dstElement.typeName}`
      );
    }

    return connectionType;
  }

  async function _getElementFromId(elementId) {
    const res = await exaptiveClient.element.read({
      data: {
        where: {
          id: elementId,
        },
      },
      params: {
        cityNamespace: 'main',
      },
    });

    if (!res.data) {
      throw new Error(`No element with id ${elementId}`);
    }

    return res.data;
  }

  async function getUserElementFromId({ cognetUserId, userElementTypeUuid }) {
    const res = await exaptiveClient.element.read({
      data: {
        where: {
          'aux.user': cognetUserId,
          type: userElementTypeUuid,
        },
      },
      params: {
        cityNamespace: 'main',
      },
    });

    if (!res.data) {
      throw new Error(`No element for user ${cognetUserId}`);
    }

    return res.data;
  }

  async function getRelatedElementTypes({ userElementType }) {
    if (!isObject(userElementType)) throw new Error('Missing userElementType');
    const configs = (
      await exaptiveClient.app.resourceConfig.read({
        resourceType: 'elementType',
        type: 'legacyForm',
        resourceId: userElementType.uuid,
        params: {
          cityNamespace: 'main',
          autoCreateNew: 'true',
        },
      })
    ).data;

    const connections = configs.connections ?? [];
    const relatedElementTypeUuids = connections.map(rel => rel.elementType);

    const relatedElementTypes = await Promise.all(
      relatedElementTypeUuids.map(uuid => _tryGetElementType(uuid))
    );
    // filter out null results
    return relatedElementTypes.filter(elementType => !!elementType);
  }

  async function _tryGetElementType(uuid) {
    try {
      const elementType = await exaptiveClient.elementType.read({
        data: { where: { uuid } },
        params: { cityNamespace: 'main' },
      });

      return elementType.data || null;
    } catch (error) {
      // Could be a problem communicating with cognet, or 404 the elementType wasn't found.
      // eslint-disable-next-line
      console.warn(`Problem retrieving elementType with uuid "${uuid}"`, error);
      return null;
    }
  }

  async function checkConnection({ cognetUserUuid, userConceptUuid, element }) {
    if (!cognetUserUuid) {
      throw new Error('Missing required argument: cognetUserUuid');
    }
    if (!userConceptUuid) {
      throw new Error('Missing required argument: userConceptUuid');
    }
    if (!element) {
      throw new Error('Missing required argument: element');
    }

    const currentConnections = await _getConnections({ src: cognetUserUuid });
    const userElementType = (
      await exaptiveClient.elementType.read({
        data: { where: { uuid: userConceptUuid } },
        params: { cityNamespace: 'main' },
      })
    ).data;

    const isConnectionType = (await _listConnectionTypes()).filter(
      conn => conn.name === 'is'
    )[0];

    const allowedConnections = userElementType?.configs?.connections ?? [];
    const connectionAllowed = allowedConnections.some(
      conn =>
        element.type === 'element' &&
        conn.elementType === element.concept.uuid &&
        isConnectionType?.uuid === conn.connectionType
    );

    const isConnected = !!currentConnections.filter(
      conn => conn.dst === element.uuid
    ).length;

    return {
      connectionAllowed,
      isConnected,
    };
  }

  async function addConnection({ userElement, elementId }) {
    const dstElement = await _getElementFromId(elementId);

    const connectionType = await _getProfileConnectionTypeFromSrcDst({
      srcElement: userElement,
      dstElement,
    });

    const submitPayload = {
      existingElementId: userElement.id,
      elementTypeUuid: userElement.type,

      properties: {},
      events: [
        {
          type: 'connect',
          elementType: dstElement.type,
          connectionType: connectionType.uuid,
          direction: 'out',
          properties: {},
          id: dstElement.id,
        },
      ],
    };

    const submitFormResponse = await exaptiveClient.app.form.submitForm({
      data: submitPayload,
      uuid: 'dynamic',
      params: { cityNamespace: 'main' },
    });

    if (submitFormResponse.response.data.status !== 'success') {
      throw new Error(submitFormResponse.response.data.message);
    }

    return submitFormResponse.data;
  }

  async function removeConnection({ userElement, elementId }) {
    const dstElement = await _getElementFromId(elementId);

    const connections = await _getConnections({
      src: userElement.uuid,
      dst: dstElement.uuid,
    });

    if (!connections.length) {
      throw new Error(
        `Could not find connections from ${userElement.id} to ${dstElement.id}`
      );
    }

    let connection;
    if (connections.length === 1) {
      connection = connections[0];
    } else {
      connection =
        connections.filter(conn => conn.typeName === 'is')[0] || null;
    }

    if (!connection) {
      throw new Error(
        `Could not determine connection to remove from ${userElement.id} to ${dstElement.id}`
      );
    }

    const submitPayload = {
      existingElementId: userElement.id,
      elementTypeUuid: userElement.type,

      properties: {},
      events: [
        {
          type: 'disconnect',
          connection: { uuid: connection.uuid },
        },
      ],
    };

    const submitFormResponse = await exaptiveClient.app.form.submitForm({
      data: submitPayload,
      uuid: 'dynamic',
      params: { cityNamespace: 'main' },
    });

    return submitFormResponse.data;
  }
}

export default UserProfile;
